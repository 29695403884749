import React from "react";
import { Box, Typography } from "@mui/material";
import background from '../assets/background.jpg'

export default class Start extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Box
                component="div"
                sx={{
                    width: '100%',
                    height: '89vh',
                    backgroundImage: `url(${background})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}>
                <Typography
                    height='50%'
                    variant='h1'
                    fontFamily='Mattilda'
                    color='primary.dark'
                    display='flex'
                    alignItems='end'
                    justifyContent='center'>
                    de Slagerij
                </Typography>
                <Typography
                    height='50%'
                    variant='h4'
                    fontFamily='Mattilda'
                    color='primary.dark'
                    display='flex'
                    alignItems='start'
                    justifyContent='center'>
                    Nice to Meat you.
                </Typography>
            </Box >
        );
    }
}
