import React from "react";
import { Box, Grid, Typography, styled } from "@mui/material";
import image from '../assets/Drinks.jpg'

const StyledTypographyTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
    fontFamily: "Deutsch",
    variant: "h6"
}));

const StyledTypographyText = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.light,
    fontFamily: "Minion Pro Regular",
    variant: "body1"
}));

const StyledParentBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    paddingTop: "8%",
    paddingLeft: "15%",
    paddingRight: "20%"
}));

export default class Drinks extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <StyledParentBox>
                <Box
                    sx={{
                        width: "260px",
                        margin: "auto",
                        paddingTop: "5vh",
                        marginBottom: "5vh",
                        background: "linear-gradient(90deg, rgba(43,91,95,255) 0% 66%, rgba(43,91,95,0) 66% 100%)",
                        flexDirection: 'column'
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box
                        component="img"
                        alt="drink"
                        src={image}
                        sx={{ maxWidth: "30vw", margin: "auto" }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    />
                    <Box>
                        <StyledTypographyTitle>
                            Drinks
                        </StyledTypographyTitle>
                    </Box>
                </Box>
                <Grid container justify="center" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <StyledTypographyText>
                            We hebben een exclusieve samenwerking met Bar Klak en slechts een beperkte drankenkaart.
                            Voor apero, degustief en koffie kan je daar terecht.
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText display={'block'} >
                            • Chaudfontaine Bruis 25cl
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText>
                            €3.00
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText display={'block'} >
                            • Chaudfontaine Plat 25cl
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText>
                            €3.00
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText display={'block'} >
                            • Coca cola classic 25cl
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText>
                            €3.50
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText display={'block'} >
                            • Coca cola zero 25cl
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText>
                            €3.50
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText display={'block'} >
                            • Fanta 25cl
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText>
                            €3.50
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText display={'block'} >
                            • Pils Estaminet 25cl
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText>
                            €2.90
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText display={'block'} >
                            • Pils 0.0 Estaminet 25cl
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText>
                            €3.00
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText display={'block'} >
                            • Cornet 33cl
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText>
                            €4.50
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText display={'block'} >
                            • Steenbrugge blond 33cl
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText>
                            €4.00
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText display={'block'} >
                            • Witte wijn glas
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText>
                            €4.50
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText display={'block'} >
                            • Witte wijn fles
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText>
                            €24.00
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText display={'block'} >
                            • Rode wijn glas
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText>
                            €4.50
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText display={'block'} >
                            • Rode wijn fles
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText>
                            €24.00
                        </StyledTypographyText>
                    </Grid>
                </Grid>
            </StyledParentBox>
        );
    }
}
