import React from "react";
import { Box, Typography, styled } from "@mui/material";
import image from '../assets/Table.png'

const StyledTypographyTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.light,
    fontFamily: "Deutsch",
    variant: "h6"
}));

const StyledTypographyText = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.light,
    fontFamily: "Minion Pro Regular",
    variant: "body1"
}));

const StyledParentBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    paddingTop: "8%",
    paddingLeft: "20%",
    paddingRight: "20%"
}));

export default class Contact extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <StyledParentBox>
                <Box
                    sx={{
                        width: "260px",
                        margin: "auto",
                        paddingTop: "5vh",
                        marginBottom: "5vh",
                        background: "linear-gradient(90deg, rgba(81,37,50,255) 0% 66%, rgba(81,37,50,0) 66% 100%)",
                        flexDirection: 'column'
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box
                        component="img"
                        alt="table"
                        src={image}
                        sx={{ maxWidth: "30vw", margin: "auto" }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    />
                    <Box>
                        <StyledTypographyTitle>
                            Place to be
                        </StyledTypographyTitle>
                    </Box>
                </Box>
                <StyledTypographyText variant="h5">
                    de Slagerij
                </StyledTypographyText>
                <br />
                <StyledTypographyText>
                    Schoenmarkt 3
                </StyledTypographyText>
                <StyledTypographyText>
                    2800 Mechelen
                </StyledTypographyText>
                <StyledTypographyText>
                    Email :
                    <a href={"mailto:hallo@ilcardinale.be"}>
                        hallo@ilcardinale.be
                    </a>
                </StyledTypographyText>
                <br />
                <StyledTypographyText>
                    Il Cardinale bv
                </StyledTypographyText>
                <StyledTypographyText>
                    BTW : BE 0546 708 232
                </StyledTypographyText>
                <StyledTypographyText>
                    Rekeningnummer : BE85 1030 3308 0506
                </StyledTypographyText>
                <StyledTypographyText>
                    IBAN : NICABEBB
                </StyledTypographyText>
                {/* <br />
                <Typography variant="body1" color={'secondary'}>
                    Bestellen en thuis laten leveren

                    Take-away / Deliveroo / Uber
                </Typography> */}
                <br />
                <br />
                <br />
                <br />
                <br />
            </StyledParentBox>
        );
    }
}
