import React from "react";
import "./AppHeader.css";
import { AppBar, Toolbar, Tabs, Tab, styled } from '@mui/material';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    background: theme.palette.primary.dark,
    position: "sticky"
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    color: theme.palette.secondary.light,
    fontFamily: "Deutsch"
}));

export default function AppHeader(props) {
    return (
        <StyledAppBar>
            <Toolbar>
                <div className="AppHeaderLogo" onClick={() => {
                    props.scrollToStart();
                }} />
                <Tabs variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                    <StyledTab label="Grill & salades" onClick={() => {
                        props.scrollToConcept();
                    }} />
                    <StyledTab label="Authentic 'Il Cardinale' burgers" onClick={() => {
                        props.scrollToFood();
                    }} />
                    <StyledTab label="Drinks" onClick={() => {
                        props.scrollToDrinks();
                    }} />
                    <StyledTab label="Contact" onClick={() => {
                        props.scrollToContact();
                    }} />
                </Tabs>
            </Toolbar>
        </StyledAppBar>
    );
}
