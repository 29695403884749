import './App.css';
import { useRef } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppHeader from './components/AppHeader';
import Start from './components/Start';
import Drinks from './components/Drinks';
import Food from './components/Food';
import Concept from './components/Concept';
import Contact from './components/Contact';

const theme = createTheme({
  palette: {
    primary: {
      light: '#B66335',
      main: '#512532',
      dark: '#281C1C'
    },
    secondary: {
      light: '#FFE5C7',
      main: '#AFD5C0',
      dark: '#2B5B5F'
    },
  },
  typography: {
    fontFamily: [
      'Mattilda',
      'Deutsch',
      'Minion Pro Regular'
    ]
  }
});

function App() {
  const startRef = useRef(null)
  const conceptRef = useRef(null)
  const foodRef = useRef(null)
  const drinksRef = useRef(null)
  const contactRef = useRef(null)

  const scrollToStart = () => {
    startRef.current.scrollIntoView({ behavior: "smooth" });
  }
  const scrollToConcept = () => {
    conceptRef.current.scrollIntoView({ behavior: "smooth" });
  }
  const scrollToFood = () => {
    foodRef.current.scrollIntoView({ behavior: "smooth" });
  }
  const scrollToDrinks = () => {
    drinksRef.current.scrollIntoView({ behavior: "smooth" });
  }
  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <ThemeProvider theme={theme}>
      <div style={{ float: "left", clear: "both" }} ref={startRef} />
      <Start />
      <AppHeader
        scrollToStart={scrollToStart}
        scrollToConcept={scrollToConcept}
        scrollToFood={scrollToFood}
        scrollToDrinks={scrollToDrinks}
        scrollToContact={scrollToContact} />
      <div style={{ float: "left", clear: "both" }} ref={conceptRef} />
      <Concept />
      <div style={{ float: "left", clear: "both" }} ref={foodRef} />
      <Food />
      <div style={{ float: "left", clear: "both" }} ref={drinksRef} />
      <Drinks />
      <div style={{ float: "left", clear: "both" }} ref={contactRef} />
      <Contact />
    </ThemeProvider>
  );
}

export default App;
