import React from "react";
import { Box, Grid, Typography, styled } from "@mui/material";
import image from '../assets/Plate.jpg'

const StyledTypographyTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.light,
    fontFamily: "Deutsch",
    variant: "h6"
}));

const StyledTypographyText = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.light,
    fontFamily: "Minion Pro Regular",
    variant: "body1"
}));

const StyledParentBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    paddingTop: "8%",
    paddingLeft: "15%",
    paddingRight: "20%"
}));

export default class Food extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <StyledParentBox>
                <Box
                    sx={{
                        width: "260px",
                        margin: "auto",
                        paddingTop: "5vh",
                        marginBottom: "5vh",
                        background: "linear-gradient(90deg, rgba(182,100,53,255) 0% 66%, rgba(182,100,53,0) 66% 100%)",
                        flexDirection: 'column'
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box
                        component="img"
                        alt="plate"
                        src={image}
                        sx={{ maxWidth: "30vw", margin: "auto" }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    />
                    <Box>
                        <StyledTypographyTitle>
                            Burgers
                        </StyledTypographyTitle>
                    </Box>
                </Box>
                <Grid container justify="center" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <StyledTypographyText >
                            de Slagerij … In Mechelen de enige locatie waar de echte 'Il Cardinale' burgers nog te vinden zijn.
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText variant="body1" display={'block'} color={'secondary'}>
                            • Classic with the twist
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText >
                            €11.50
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={12}>
                        <StyledTypographyText variant="body2">
                            Bacon, tomaat, ijsbergsla, zoetzure augurk
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText >
                            • Classic cheese
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText >
                            €12.00
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={12}>
                        <StyledTypographyText variant="body2">
                            Bacon, tomaat, ijsbergsla, zoetzure augurk, cheddar
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText >
                            • The Godfather
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText >
                            €13.00
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={12}>
                        <StyledTypographyText variant="body2">
                            Tapenade van gedroogde tomaat, mozzarella, pesto, italiaanse ham, krokantje parmezaan, tuinkers
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText >
                            • Holy Guacamole
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText variant="body1" display={'block'} color={'secondary'}>
                            €13.50
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={12}>
                        <StyledTypographyText variant="body2">
                            Guacamole, tomatensalsa, zure room, rode ajuin, zoute popcorn
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText >
                            • Way to Veggie
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText >
                            €12.50
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={12}>
                        <StyledTypographyText variant="body2">
                            Burger van kikkererwten, curry-dressing, ijsbergsla, tomaat, rode ajuin
                        </StyledTypographyText>
                    </Grid>
                </Grid>
                <br />
                <StyledTypographyText variant="body1" display={'block'} color={'secondary'}>
                    Bij alle burgers kan het vlees gekozen worden: Rund, kip of lam
                </StyledTypographyText>
            </StyledParentBox>
        );
    }
}
