import React from 'react';
import { createRoot } from "react-dom/client";
import './index.css';
import App from './App';
import { CssBaseline } from "@mui/material";
import { Helmet } from "react-helmet";


createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <CssBaseline />
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Restaurant",
          "name": "de Slagerij",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Schoenmarkt 3",
            "addressLocality": "Mechelen",
            "addressRegion": "Antwerpen",
            "postalCode": "2800",
            "addressCountry": "BE"
          },
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": 51.027702719329774,
            "longitude": 4.479591857068137
          },
          "url": "https://deslagerijmechelen.be",
          // "email": "contact@deslagerijmechelen.be",
          // "telephone": "+32478273572",
          "servesCuisine": "Burgers",
          "priceRange": "$$",
          "acceptsReservations": "True",
          "menu": "https://www.deslagerijmechelen.be",
          "openingHoursSpecification": [
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
              ],
              "opens": "18:00",
              "closes": "23:00"
            }
          ]
        })}
      </script>
    </Helmet>
    <App />
  </React.StrictMode>
);