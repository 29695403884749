import React from "react";
import { Box, Grid, Typography, styled } from "@mui/material";
import image from '../assets/Grill.jpg'

const StyledTypographyTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.dark,
    fontFamily: "Deutsch",
    variant: "h6"
}));

const StyledTypographyText = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.light,
    fontFamily: "Minion Pro Regular",
    variant: "body1"
}));

const StyledParentBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    paddingTop: "8%",
    paddingLeft: "15%",
    paddingRight: "20%"
}));

export default class Concept extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <StyledParentBox>
                <Box
                    sx={{
                        width: "260px",
                        margin: "auto",
                        paddingTop: "5vh",
                        marginBottom: "5vh",
                        background: "linear-gradient(90deg, rgba(175,213,192,255) 0% 66%, rgba(175,213,192,0) 66% 100%)",
                        flexDirection: 'column'
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box
                        component="img"
                        alt="plate"
                        src={image}
                        sx={{ maxWidth: "30vw", maxHeight: "40vh", margin: "auto" }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    />
                    <Box>
                        <StyledTypographyTitle>
                            Grill & salades
                        </StyledTypographyTitle>
                    </Box>
                </Box>
                <StyledTypographyText>
                    Welkom in de Slagerij!
                    Hou je van een heerlijk en eerlijk stukje vlees, dan vind je zonder meer je gading vindt in onze vleestoog.
                    Maak je keuze en bepaal je favoriete ‘cuisson’.
                </StyledTypographyText>
                <br />
                <StyledTypographyText>
                    Neem je groentekom (groot of klein) en bedien je aan ons groentebuffet.
                    Desgewenst nog een heerlijk frietje met sausje erbij en een drankje!
                </StyledTypographyText>
                <br />
                <StyledTypographyText>
                    Ondertussen is je stukje vlees gebakken en wandel je met je plateau naar onze eetkamer of terras.
                </StyledTypographyText>
                <br />
                <StyledTypographyText>
                    De Slagerij is in de professionele handen van onze huiskeurslager sedert jaren: Dirk
                    Verschueren! Ofschoon op rust na een succesvolle carrière als keurslager stelt hij zijn
                    expertise met veel plezier ten dienste van onze Slagerij/restaurant. Dirk is de man die jaren
                    terug met zijn kunde, hart en ziel de Il Cardinale-burgers tot een populaire delicatesse
                    maakte.
                </StyledTypographyText>
                <br />
                <StyledTypographyText>
                    Wij zijn verheugd dat hij vandaag de kwaliteitscontroleur blijft voor àlles wat in onze
                    vleestoog wordt aangeboden!
                </StyledTypographyText>
                <br />
                <Grid container justify="center" alignItems="center" spacing={2}>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText display={'block'}>
                            • Vleesje van de grill
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText>
                            vanaf €4.00
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText display={'block'}>
                            • Warme saus
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText>
                            €2.00
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText display={'block'}>
                            • Portie frietjes
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText>
                            €4.50
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText display={'block'}>
                            • Frietsaus (mayo, kethup, tartaar)
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText>
                            €1.00
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText display={'block'}>
                            • Gevulde aardappel
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText>
                            €4.50
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText display={'block'}>
                            • Groentenbowl groot
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText>
                            €8.00
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText display={'block'}>
                            • Groentenbowl klein
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText>
                            €5.00
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText display={'block'}>
                            • Broodje
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText>
                            €1.50
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={11} md={10}>
                        <StyledTypographyText display={'block'}>
                            • Visschoteltje koud
                        </StyledTypographyText>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <StyledTypographyText>
                            €5.00
                        </StyledTypographyText>
                    </Grid>
                </Grid>
            </StyledParentBox>
        );
    }
}
